@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400&display=swap');

:root {
    --primary-color: #4a4a4a;
    --secondary-color: #6699cc;
    --background-color: #f9f9f9;
    --card-background-color: #ffffff;
    --button-background-color: #6699cc;
    --button-text-color: #ffffff;
    --input-background-color: #f0f0f0;
    --input-border-color: #dcdcdc;
    --highlight-color: #ffcccc;
    --label-color: #6b6b6b; /* New variable for dark grey label color */
    --value-color: #4a4a4a;
    --gradient-start: #bfdffe; /* original 88bbee / light bedfff / a7d2fc */
    --gradient-mid: #cceaff;  /* b8e1ff */
    --gradient-end: #ffcbdc; /* original ffccdd / light efc6d4 / ffccdd*/
    --hover-color: #aabbee;
}

@keyframes liquidFlow1 {
    0%, 100% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
}

@keyframes liquidFlow2 {
    0%, 100% { background-position: 100% 50%; }
    50% { background-position: 0% 50%; }
}

@keyframes liquidFlow3 {
    0%, 100% { background-position: 50% 0%; }
    50% { background-position: 50% 100%; }
}

@keyframes liquidFlow4 {
    0%, 100% { background-position: 50% 100%; }
    50% { background-position: 50% 0%; }
}

@media (max-width: 768px) {
    .pools-container {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        padding: 0 10px; /* Adjust padding for better alignment */
    }

    .pool {
        width: 100% !important; /* Ensure the pool takes up full width minus padding */
        margin-bottom: 20px;
        padding: 15px;
        box-sizing: border-box;
        background-color: var(--card-background-color);
        border-radius: 12px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .staked-pools-container {
        flex-direction: column;
        padding: 0 10px; /* Adjust padding for better alignment */
    }

    .staked-pool {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px;
        box-sizing: border-box;
    }

    .header {
        flex-direction: column;
        padding: 10px 20px;
    }
    
    .header-logo {
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .wallet-info {
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }

    .modal-content,
    .overlay-modal .modal-content {
        width: 90%;
        max-width: 350px;
    }

    .header-logo img.logo-image {
        width: 40px;
        margin-right: 5px;
    }

    .footer {
        font-size: 14px;
        padding: 20px 10px;
    }
}



body {
    background: 
        radial-gradient(circle at 30% 30%, var(--gradient-start) 0%, transparent 50%),
        radial-gradient(circle at 70% 70%, var(--gradient-end) 0%, transparent 50%),
        radial-gradient(circle at 50% 50%, var(--gradient-mid) 0%, transparent 60%),
        radial-gradient(ellipse at 20% 80%, var(--gradient-start) 0%, transparent 70%),
        radial-gradient(ellipse at 80% 20%, var(--gradient-end) 0%, transparent 70%),
        radial-gradient(circle at 40% 60%, #c4e0ff 0%, transparent 40%),
        radial-gradient(circle at 60% 40%, #ffe6e6 0%, transparent 40%),
        linear-gradient(45deg, var(--gradient-start) 0%, var(--gradient-mid) 25%, #d9e8ff 50%, var(--gradient-mid) 75%, var(--gradient-end) 100%);
    background-size: 
        400% 400%,
        400% 400%,
        200% 200%,
        300% 300%,
        300% 300%,
        200% 200%,
        200% 200%,
        200% 200%;
    background-attachment: fixed;
    animation: 
        liquidFlow1 20s ease infinite,
        liquidFlow2 25s ease infinite,
        liquidFlow3 30s ease infinite,
        liquidFlow4 35s ease infinite;
    color: var(--primary-color);
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

.gradient-text {
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
    /* font-family: "Satoshi", "Satoshi Placeholder", sans-serif; */
}

h1, h2, h3, h4, h5, h6 {
    color: var(--primary-color);
    font-family: "Noto Sans Adlam Unjoined", "Noto Sans Adlam Unjoined Placeholder", sans-serif;
    margin: 0;
    padding: 0;
}

a {
    color: var(--secondary-color);
    text-decoration: none;
}

a:hover {
    color: var(--highlight-color);
}

button {
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button:hover {
    background-color: var(--hover-color);
}

input[type="text"],
input[type="number"],
input[type="email"],
textarea {
    background-color: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

.input-field {
    font-size: 18px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.balance-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.max-link {
    color: var(--secondary-color);
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
}

.max-link:hover {
    color: var(--hover-color);
}

.staking-pools.greyed-out {
    pointer-events: none;
    opacity: 0.5;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background: var(--background-color);
    padding: 30px;
    border-radius: 12px;
    z-index: 1001;
    width: 450px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.modal-content p {
    margin: 10px 0;
    font-size: 16px;
    color: var(--primary-color);
}

.modal-buttons,
.overlay-modal .modal-buttons {
    display: flex;
    justify-content: center;
    gap: 20px; /* Ensure adequate spacing between buttons */
    margin-top: 30px; /* Space between content and buttons */
}

.overlay-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.overlay-modal .modal-content {
    background: var(--background-color);
    padding: 30px;
    border-radius: 12px;
    z-index: 1001;
    width: 450px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.overlay-modal .modal-content .spinner {
    margin: 20px auto;
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--secondary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.pools-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    padding: 0 20px;
}

.pool {
    background: var(--card-background-color);
    padding: 20px;
    border-radius: 12px;
    width: 30%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.pool:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.pool h2 {
    font-size: 25px; /* Increased font size */
    color: var(--secondary-color);
    margin-bottom: 20px;
    text-align: center;
    color: #567aa3;
    font-weight: 800; /* Increased weight for more emphasis */
    letter-spacing: 1px; /* Added spacing for a more polished look */
}

.pool p {
    font-size: 18px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
}

.pool p .label {
    font-weight: bold;
    color: var(--label-color);
    padding-right: 10px; /* Add spacing between label and value */
}

.pool p .value {
    text-align: right;
    color: var(--value-color);
    font-size: 20px;
}

.connect-wallet {
    text-align: center;
    margin-top: 20px;
}

.staked-pools-section {
    padding: 0 20px;
    margin-top: 20px;
}

.staked-pools-container {
    background: var(--card-background-color);
    padding: 20px;
    border-radius: 12px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.staked-pool {
    flex: 1;
    background: var(--background-color);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--input-border-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Ensure the content and buttons are in a column layout */
    justify-content: space-between; /* Pushes the buttons to the bottom */
}

.staked-pool:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.staked-pool-info {
    margin-bottom: 20px;
    text-align: left;
    flex-grow: 1; /* Makes the info section take up the remaining space */
}

.staked-pool-info h3 {
    font-size: 22px; /* Increased font size */
    font-weight: 800; /* Increased weight for more emphasis */
    margin-bottom: 20px;
    text-align: center; /* Center the header text */
    color: #567aa3;
    letter-spacing: 1px; /* Added spacing for a more polished look */
}

.staked-pool-info p {
    font-size: 18px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
}

.staked-pool-info .label {
    font-weight: bold;
    color: var(--label-color); /* Label color */
    padding-right: 10px; /* Add spacing between label and value */
}

.staked-pool-info .value {
    text-align: right;
    color: var(--value-color);
    font-size: 16px;
}
.staked-pool-actions {
    display: flex;
    flex-direction: column;
    gap: 0px; /* Maintain the gap between buttons */
}
.staked-pool-actions button {
    width: 100%;
    align-self: flex-start;
    margin-bottom: 5px; /* Increase spacing between buttons */
}

.staked-pool-actions button:last-child {
    margin-bottom: 0; /* Remove bottom margin from the last button */
}

.wallet-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.wallet-info p {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: var(--input-background-color);
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo img.logo-image {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.gradient-text {
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
}

.error-message p {
    background-color: var(--highlight-color);
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 20px;
}

.notice-message {
    color: #dd5b86 !important; /* Forces this color to be applied */
}

.footer {
    background: none;
    text-align: center;
    padding: 10px 20px;
    font-size: 12px;
    color: var(--primary-color);
    box-shadow: none;
    margin-top: auto;
}

.footer a {
    color: var(--secondary-color);
    text-decoration: none;
    margin: 0 10px;
}

.footer a:hover {
    color: var(--highlight-color);
}

